@font-face {
    font-family: "OpenSans";
    src: url("../../font/OpenSans-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "OpenSansBold";
    src: url("../../font/OpenSans-Bold.ttf") format("truetype");
    font-display: swap;
}


.footer {
    display: flex;
    flex-direction: column;
    background-color: #ACB9A5;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px;
}

.footer-branding {
    text-align: center;
}

.footer-branding h1 {
    font-size: 64px;
    font-family: "OpenSansBold";
}

.footer-content {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.footer-section {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    font-size: 24px;
}

.footer-section h4 {
    margin: 20px 0;
    margin-bottom: 20px;
    font-family: "OpenSansBold";
}

.footer-section p,
.footer-section a {
    color: white;
    font-family: "OpenSans";
    text-decoration: none;
    line-height: 1.6;
    margin: 0;
    margin-bottom: 10px;
}

.footer-section a:hover {
    text-decoration: underline;
}  

@media (max-width: 1640px) and (min-width: 1450px) { 
    .footer-branding h1 {
        font-size: 50px;
    }

    .footer-section {
        font-size: 18px;
    }
}

@media (max-width: 1449px) and (min-width: 700px) { 
    .footer-branding h1 {
        font-size: 40px;
    }

    .footer-section {
        font-size: 16px;
    }
}

/* Mobile ---------------------------- */
@media (max-width: 699px) { 
    .footer {
        display: flex;
        flex-direction: column;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
    }
    
    .footer-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: flex-start;
    }
    
    .footer-section {
        display: flex;
        flex-direction: column;
        font-size: 20px;
    }
    
    
    .footer-section p,
    .footer-section a {
        color: white;
        text-decoration: none;
        line-height: 1.6;
        margin: 0;
    }
    
    .footer-section a:hover {
        text-decoration: underline;
    }
    
    .footer-branding {
        text-align: center;
        flex: 1;
    }
    
    .footer-branding h1 {
        font-size: 40px
    }
}