@font-face {
    font-family: "LoraBold";
    src: url("../../font/Lora-Bold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "OpenSans";
    src: url("../../font/OpenSans-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "OpenSansBold";
    src: url("../../font/OpenSans-Bold.ttf") format("truetype");
    font-display: swap;
}


.gites-section1 {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: transparent;    
}

.gites-video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}

.gites-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gites-content {
    background-color: white;
}

.gites-section1-reservation-container {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.gites-section1-gites-selection,
.gites-section1-dates-calendar {
    padding: 30px 15px;
    font-size: 16px;
    border: none;
    border-radius: 0; 
    background-color: #fff; 
    height: 100%;
}

.gites-section1-dates-calendar.disabled {
    background-color: #cecece; 
    border: 1px solid #cecece;
}

.gites-section1-date-choice {
    width: 120px;
    border-left: 1px solid black;
}

.gites-section1-reservation-button {
    padding: 32px 20px;
    font-size: 16px;
    color: white;
    background-color: #ACB9A5;
    border: none;
    border-radius: 0;
    cursor: pointer;
}

.gites-section1-reservation-button:hover, .gites-section2-button:hover {
    background-color: #8c9785;
}

.gites-section2 {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
}

.gites-section2-text-edit {
    width: 50%;
    font-size: 20px;
    margin-left: 60px;
}

.gites-edit-button {
    display: inline-block;
    padding: 3px 40px;
    margin-top: 20px;
    background-color: #ACB9A5;
    color: white;
    text-decoration: none;
    border-radius: 0;
    font-size: 20px;
    border: none;
}

.gites-section2-text-edit-area {
    width: 50%;
    font-size: 16px;
    height: 200px;
    margin-left: 60px;
    color: black;
}

.gites-section2 h1 {
    margin: 0;
    margin-top: 40px;
    font-size: 64px;
    color: #ACB9A5;
    font-family: "LoraBold";
}

.gites-section2 p {
    max-width: 80%;
    margin-top: 40px;
    margin-bottom: 100px;
    font-size: 25px;
    font-family: "OpenSans";
}

.gites-section2-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin-top: 0;
    width: 100%;
}

.gites-section2-content.reverse {
    flex-direction: row-reverse;
}

.gites-slider-container {
    display: flex;
}

.gites-slider {
    display: flex;
}

.gites-section2-image {
    width: 50%;
}

.gites-section2-text {
    width: 50%;
    text-align: left;
}

.gites-section2-text.reverse {
    width: 50%;
    text-align: left;
}

.gites-section2-text h3 {
    font-size: 40px;
    padding-left: 15%;
    font-family: "OpenSansBold";
}

.gites-section2-text p {
    font-size: 16px;
    width: 50%;
    padding-left: 15%;
    font-family: "OpenSans";
}

.gites-section2-button {
    display: inline-block;
    padding: 10px 80px;
    margin-top: 20px;
    background-color: #ACB9A5;
    color: white;
    text-decoration: none;
    border-radius: 0;
    font-size: 24px;
    font-family: "OpenSans";
    margin-left: 15%;
}

.gites-section-map {
    display: flex;
    justify-content: center;
    padding: 100px 0;
}

@media (max-width: 1449px) and (min-width: 700px) { 
    .gites-section-map img {
        width: 90%;
    }
}


/* Mobile ---------------------------- */
@media (max-width: 699px) {            
    .gites-section1-reservation-container {
        display: flex;
        flex-direction: column;
        bottom: 1%;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
    }
    
    .gites-section1-reservation-button {
        padding: 20px 10px;
        width: 160px;
        margin-bottom: 40px;
    }
    
    .gites-section2 {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 0;
    }
    
    .gites-section2 h1 {
        font-size: 48px;
    }
    
    .gites-section2 p {
        max-width: 100%;
        text-align: justify;
        margin: 0px;
        padding: 20px;
        font-size: 20px;
    }
    
    .gites-section2-content, .gites-section2-content.reverse {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 50px;
        margin-top: 0;
        width: 100%;
    }
    
    .gites-section2-image {
        width: 100% !important;
        height: 100% !important;
    }
    
    .gites-section2-text, .gites-section2-text.reverse {
        display: flex;
        flex-direction: column;
        width: 90%;
        padding: 0 20px;
    }
    
    .gites-section2-text h3, .gites-section2-text.reverse h3 {
        font-size: 40px;
        padding: 0 20px;
    }
    
    .gites-section2-text p, .gites-section2-text.reverse p {
        font-size: 16px;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    
    .gites-section2-button {
        padding: 10px 20px;
        margin: 30px;
        align-self: center;
    }
    
    .gites-section-map img {
        width: 100%;
        height: 100%;
    }
}