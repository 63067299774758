@font-face {
    font-family: "OpenSans";
    src: url("../../font/OpenSans-Regular.ttf") format("truetype");
    font-display: swap;
}

.admin-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 8%;
    padding-bottom: 5%;
}

.admin-dashboard-title h1 {
    margin: 0;
    font-size: 40px;
    padding-left: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: "OpenSans";
    font-style: italic;
}

.admin-dashboard-select-race {
    width: 500px;
    z-index: 100000;
}

.admin-dashboard-tax {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    max-width: 200px;
    gap: 10px;
}

.admin-dashboard-tax label {
    font-size: 24px;
    font-family: "OpenSans";
}

.admin-dashboard-choose-race {
    font-weight: 600;
    font-size: 18px;
}

.admin-dashboard-ranking-view {
    width: 100%;
    display: flex;
    justify-content: center;
}

.admin-dashboard-select-cyclist {
    width: 30%;
    margin-left: 30px;
}

.admin-dashboard-cyclists-view {
    width: 70%;
}

.admin-dashboard-controls {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-top: 10px;
    padding-left: 5%;
    padding-bottom: 20px;
}

.admin-dashboard-sort-control {
    font-family: "OpenSans";
    font-size: 18px;
    letter-spacing: 2px;
}
  
.admin-dashboard-sort-control, .admin-dashboard-search-control {
    display: flex;
    align-items: center;
}

.admin-dashboard-sort-control select, .admin-dashboard-search-control input {
    font-family: "OpenSans";
    font-size: 12px;
    width: 200px;
    height: 30px;
    border-radius: 22px;
    border: none;
    background-color: #FAFAFA;
}

.admin-dashboard-sort-control label {
    padding-bottom: 6px;
}

.admin-dashboard-search-control {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-dashboard-search-icon {
    position: absolute;
    left: 5px;
    top: 5px;
}
  
.admin-dashboard-search-control input {
    padding-left: 30px;
}

.admin-dashboard-reservations-table {
    width: 90%;
    overflow-y: auto;
    margin-left: 5%;
    padding: 0;
    letter-spacing: 0;
}

.admin-dashboard-reservations-table table {
    width: 100%;
    border-collapse: collapse;
    font-family: "OpenSans";
    background-color: #FAFAFA;
    max-height: 700px;
}

.admin-dashboard-reservations-table th,
.admin-dashboard-reservations-table td  {
    border: 1px solid black;
    padding: 9px;
    text-align: center;
    border-left: none;
}

.admin-dashboard-reservations-table td {
    cursor: pointer;
    font-size: 13px;
}

.admin-dashboard-reservations-table th {
    font-family: "OpenSans";
    background-color: #ACB9A5;
    border-top: none;
    position: sticky;
    top: 0;
    z-index: 100;
    font-size: 14px;
}

.admin-dashboard-reservations-button, .admin-dashboard-block-button {
    padding: 10px 10px;
    background-color: #ACB9A5;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 12px; 
    border-radius: 0px;
    font-family: "OpenSans";
}

.admin-dashboard-ranking-view {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.admin-dashboard-ranking-view p {
    margin-left: 5%;
    margin-bottom: 0;
}

.admin-dashboard-block-dates-container {
    width: 90%;
}

.admin-dashboard-dates {
    display: flex;
    width: 80%;
}

.admin-dashboard-dates-calendar {
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    margin-top: 5px;
    margin-left: 0;
    flex: 1;
}

.admin-dashboard-block-dates-selector {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    max-width: 200px;
}

.admin-dashboard-block-dates-selector select {
    padding: 10px;
    margin-right: 10px;
    flex: 1;
}