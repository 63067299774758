@font-face {
    font-family: "LoraBold";
    src: url("../../font/Lora-Bold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "OpenSans";
    src: url("../../font/OpenSans-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "OpenSansBold";
    src: url("../../font/OpenSans-Bold.ttf") format("truetype");
    font-display: swap;
}

.gite-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }
  
.gite-main-section {
    display: flex;
    flex-direction: column;
}

.gite-main-infos {
    display: flex;
    margin-top: 10%;
}

.gites-details-edit-button {
    display: inline-block;
    padding: 3px 40px;
    margin-top: 20px;
    background-color: #ACB9A5;
    color: white;
    text-decoration: none;
    border-radius: 0;
    font-size: 20px;
    border: none;
    z-index: 10;
}

.gites-details-text-edit-area {
    width: 70%;
    font-size: 16px;
    height: 200px;
    margin-left: 60px;
    color: black;
}

.gites-details-text-edit-input {
    width: 50%;
    font-size: 16px;
    margin-left: 60px;
    color: black;
}


.gite-details-container {
    flex: 1;
}

.gite-details-main-image {
    width: 100%;
    height: 60%;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}

.gite-details-main-image img {
    border-radius: 20px;
    width: 70%;
    height: 100%;
    margin-right: 50px;
}

.gite-details-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gite-details-text {
    width: 30%;
    padding: 10px;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    font-family: "OpenSans";
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.gite-details-text h3 {
    font-size: 24px;
    font-weight: 400;    
}

.gite-details-text p {
    margin: 0;
    font-size: 14px;
}

.gite-details-slider {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 400px;
    height: 150px;
    padding: 0 30px;
}

.gite-details-slider-edit {
    display: flex;
    flex-direction: column;
}

.gite-details-slider-edit p {
    font-size: 12px;
    margin: 0;
}

.gite-details-slider-edit-position {
    display: flex;
    width: 100%;
}

.gite-details-slider-edit-position input {
    width: 50px;
    margin: 0 5px;
    font-size: 12px;
}

.gite-details-slider-edit-position button {
    width: 50%;
}


.gite-details-add-image {
    height: 25px;
}

.gite-details-slider-container {
    width: calc(100% + 40px);
    width: 100%;
    outline: none;
}

.gite-details-thumbnail-container {
    padding: 0 10px;
    height: 50%;
}  

.slick-prev:before,
.slick-next:before {
    color: black;
}


.slick-prev {
    left: -25px;
    z-index: 1;
}

.slick-next {
    right: -25px;
    z-index: 1;
}
  
.gite-details-thumbnail {
    width: calc(90% - 20px);
    height: 90px;
    cursor: pointer;
    transition: transform 0.2s;
    border-radius: 6px;
}

.gite-details-thumbnail:hover {
    outline: none;
}

.gite-details-thumbnail-container:focus {
    outline: none;
}  

.gite-details-content-container {
    flex: 1;    
}

.gite-details-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5%;
    width: 70%;
    font-family: "OpenSans";
}

.gite-details-content h1 {
    font-size: 28px;
    color: #333; 
    margin-top: 0;
    font-family: "OpenSansBold";
}

.gite-details-price-link {
    font-size: 18px;
    font-weight: 600;
    color: inherit;
    text-decoration: none;   
    cursor: pointer; 
}

.gite-details-price-link-other {
    font-size: 18px;
    font-weight: 500;
    color: inherit;
    text-decoration: none;   
    cursor: pointer; 
    padding-left: 20px;
}

.gite-details-content p {
    margin-top: 50px;
    font-size: 14px;
}

.gite-details-content button {
    padding: 10px 50px;
    background-color: #ACB9A5;
    color: white; 
    border: none; 
    cursor: pointer;
    border-radius: 26px;
    font-size: 18px;
    margin-top: 40px;
    align-self: flex-end;
}

.gite-details-content button:hover {
    background-color: #8c9785;
}

.gite-equipments-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gite-equipments-container h2 {
    font-size: 40px;
    margin-bottom: 10px;
}

.gite-details-equipments {
    display: flex;
    width: 60%;
}

.gite-details-equipments-left, .gite-details-equipments-right {
    flex: 1;
    background-color: #E7E6E6;
    margin: 10px;
}

.gite-details-equipments ul {
    padding: 20px 100px;
}

.gite-details-equipments li {
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 500;
}

.gite-details-other {
    display: flex;
    flex-direction: column;
    margin: 100px 0;
}

.gite-details-other h2 {
    font-size: 40px;
    margin-left: 20%;
    font-family: "OpenSans";
    font-weight: 600;
}

.gite-details-other-container {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.gite-details-other-gite-item {
    background-color: #FAFAFA;
    border-radius: 16px;
}

.gite-details-other-gite-item img {
    width: 400px;
    height: 290px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}

.gite-details-other-gite-item h3 {
    font-size: 24px;
    font-family: "OpenSans";
    font-weight: 600;
}

.gite-details-other-gite-item p {
    font-size: 16px;
    font-family: "OpenSans";
}

.gite-details-other-gite-item h3, .gite-details-other-gite-item p {
    padding-left: 20px;
}

.other-gite-link {
    text-decoration: none;
    color: inherit;
  }

.gite-details-section-map {
    display: flex;
    justify-content: center;
    margin: 100px 0;
}

@media (max-width: 1640px) and (min-width: 1450px) { 
    .gite-details-text h3 {
        font-size: 20px;  
    }

    .gite-details-text p {
        font-size: 14px;
    }

    .gite-details-thumbnail {
        width: calc(90% - 20px);
        height: 70px;
        cursor: pointer;
        transition: transform 0.2s;
        border-radius: 6px;
    }
}

@media (max-width: 1449px) and (min-width: 700px) { 
    .gite-details-main-image {
        width: 100%;
        height: 50%;
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-end;
    }

    .gite-details-slider {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 10px;
        width: 300px;
        height: 100px;
        padding: 0 30px;
    }

    .gite-details-thumbnail-container {
        padding: 0;
        height: 100px;
        display: flex !important;
        align-items: center;
        justify-content: center;
    }  

    .gite-details-thumbnail {
        height: 70px;
        
    }

    .gite-details-text h3 {
        font-size: 18px;  
    }

    .gite-details-text p {
        font-size: 12px;
    }

    .gite-details-content {
        width: 90%;
    }

    .gite-details-content p {
        font-size: 12px;
    }

    .gite-details-section-map img {
        width: 90%;
    }
}

/* Mobile ---------------------------- */
@media (max-width: 699px) { 
    .gite-details {
        padding: 40px 0;
    }
      
    .gite-main-section {
        display: flex;
        flex-direction: column;
    }

    .gite-main-infos {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .gite-details-container {
        width: 100%;
    }
    
    .gite-details-main-image img {
        border-radius: 0px;
        width: 100%;
        margin-right: 0;
    }
    
    .gite-details-bottom {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
    }
    
    .gite-details-text {
        align-self: center;
        width: 80%;
        margin: 20px 0;
    }
    
    .gite-details-slider {
        flex: 1;
        display: flex;
        align-items: center;
        align-self: center;
        gap: 10px;
        width: 80%;
        height: 100px;
        padding: 0 10px;
    }
 
    .gite-details-thumbnail {
        height: 70px;
    }
    
    .gite-details-content-container {
        display: flex;
        flex-direction: column;   
    }
    
    .gite-details-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
        width: 100%;
    }
    
    .gite-details-content h1 {
        font-size: 28px;
        color: #333; 
        margin: 0 20px;
    }
    
    .gite-details-content strong {
        font-size: 18px;
        align-self: flex-start;
        margin: 20px;  
    }
    
    .gite-details-content p {
        margin: 0 20px; 
        text-align: justify;       
    }
    
    .gite-details-content button {
        align-self: center;
    }
    
    .gite-equipments-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .gite-equipments-container h2 {
        font-size: 32px;
        margin-bottom: 10px;
    }
    
    .gite-details-equipments {
        display: flex;
        flex-direction: column;
        width: 90%;
    }
    
    .gite-details-other {
        display: flex;
        flex-direction: column;
    }
    
    .gite-details-other h2 {
        font-size: 32px;
        margin-left: 0;
        align-self: center;
    }
    
    .gite-details-other-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
    }
    
    .gite-details-other-gite-item img {
        width: 100%;
        height: 290px;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }
    
    .gite-details-other-gite-item h3 {
        font-size: 24px;
        margin: 0;
    }

    .gite-details-section-map img {
        width: 100%;
        height: 100%;
    }
}
