@font-face {
    font-family: "LoraBold";
    src: url("../../font/Lora-Bold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "OpenSans";
    src: url("../../font/OpenSans-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "OpenSansBold";
    src: url("../../font/OpenSans-Bold.ttf") format("truetype");
    font-display: swap;
}

.contact-section1 {
    position: relative;
    width: 100%;
}

.contact-section1 img {
    width: 100%;
    object-fit: cover;
}

.contact-section1-reservation-container {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.contact-section1-gites-selection,
.contact-section1-dates-calendar {
    padding: 30px 15px;
    font-size: 16px;
    border: none;
    border-radius: 0; 
    background-color: #fff; 
    height: 100%;
}

.contact-section1-dates-calendar.disabled {
    background-color: #cecece; 
    border: 1px solid #cecece;
}

.contact-section1-date-choice {
    width: 100px;
    border-left: 1px solid black;
}

.contact-section1-reservation-button {
    padding: 32px 20px;
    font-size: 16px;
    color: white;
    background-color: #ACB9A5;
    border: none;
    border-radius: 0;
    cursor: pointer;
}

.contact-section1-reservation-button:hover {
    background-color: #8c9785;
}

.contact-section2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    font-family: "OpenSans";
}

.contact-section2 h1 {
    font-size: 60px;
    font-family: "LoraBold";
    color: #ACB9A5;
}

.contact-section2-presentation {
    display: flex;
    flex-direction: column;
    max-width: 80%;
}

.contact-section2-presentation h2 {
    font-size: 46px;    
    font-weight: 400;
    margin: 0;
}

.contact-section2-presentation p {
    font-size: 24px;
}

.contact-section2-content {
    display: flex;
    width: 80%;
}

.contact-section2-info {
    flex: 1;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 24px;
}

.contact-section2-form {
    max-width: 50%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.contact-section2-form form {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.contact-section2-form label {
    margin-top: 10px;
    font-size: 20px;
}

.contact-section2-form input[type="text"],
.contact-section2-form input[type="email"],
.contact-section2-form textarea {
    margin-top: 5px;
    padding: 10px;
    border: 1px solid #14274A80;
    border-radius: 4px;
    font-size: 20px;
}

.contact-section2-form textarea {
    height: 250px;
}

.contact-section2-text-edit {
    width: 50%;
    font-size: 16px;
    height: 50px;
}

.contact-section2-text-edit-area {
    width: 1000px;
    height: 50px;
    font-size: 16px;
}

.contact-edit-button {
    display: inline-block;
    padding: 3px 40px;
    margin-top: 20px;
    background-color: #ACB9A5;
    color: white;
    text-decoration: none;
    border-radius: 0;
    font-size: 20px;
    border: none;
    width: 50%;
}

.contact-edit-button:hover {
    background-color: #8c9785;
}


.contact-section2-form button {
    margin-top: 30px;
    padding: 20px 0;
    background-color: #ACB9A5;
    color: white;
    border: none;
    cursor: pointer;
    width: 160px;
    font-size: 20px;
    margin-left: auto;
}

.contact-section2-form button:hover {
    background-color: #8c9785;
}

.contact-section2-address, .contact-section2-phone {
    margin: 0;
}

.contact-section-map {
    display: flex;
    justify-content: center;
    margin: 100px 0;
}

@media (max-width: 1449px) and (min-width: 700px) { 
    .contact-section2 h1 {
        font-size: 50px;
    }

    .contact-section2-presentation h2 {
        font-size: 36px;    
    }

    .contact-section2-presentation p {
        font-size: 22px;
    }

    .contact-section2-info {
        font-size: 22px;
    }

    .contact-section2-form label {
        font-size: 18px;
    }

    .contact-section2-form input[type="text"],
    .contact-section2-form input[type="email"],
    .contact-section2-form textarea {
    font-size: 18px;
}

    .contact-section-map img {
        width: 90%;
    }
}

/* Mobile ---------------------------- */
@media (max-width: 699px) {   
    .contact-section1-reservation-container {
        display: flex;
        flex-direction: column;
        bottom: 1%;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
    }
    
    .contact-section1-reservation-button {
        padding: 20px 10px;
        width: 160px;
        margin-bottom: 40px;
    }
    
    .contact-section2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        display: flex;
    }
    
    .contact-section2 h1 {
        font-size: 48px;
    }
    
    .contact-section2-presentation {
        display: flex;
        flex-direction: column;
        max-width: 90%;
    }
    
    .contact-section2-presentation h2 {
        font-size: 30px;
    }
    
    .contact-section2-presentation p {
        font-size: 20px;
        align-self: center;
        text-align: justify;
    }
    
    .contact-section2-content {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .contact-section2-info {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }
    
    .contact-section2-form {
        max-width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    
    .contact-section2-form form {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    .contact-section2-form label {
        margin-top: 10px;
        font-size: 16px;
    }
    
    .contact-section2-form input[type="text"],
    .contact-section2-form input[type="email"],
    .contact-section2-form textarea {
        font-size: 20px;
    }
    
    .contact-section2-form textarea {
        height: 200px;
    }
    
    .contact-section2-form button {
        padding: 15px 0;
        font-size: 20px;
    }
    
    .contact-section2-address, .contact-section2-phone {
        margin: 0;
    }
    
    .contact-section-map img {
        width: 100%;
        height: 100%;
    }
}