.reservation {
    margin-top: 200px;
}

.payment-form {
    width: 30%;
    height: 500px;
}

.reservation-container {
    display: flex;
    width: 80%;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 10%;
}

.reservation-image {
    flex: 1;
}

.reservation-image img {
    width: 100%;  
    height: 100%;
}

.reservation-divider {
    width: 50px;
    background-color: #ACB9A5;
}

.reservation-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
}

.reservation-customer {
    display: flex;
    align-items: center;
    width: 80%;
}

.reservation-customer-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: left;
    padding: 10px 5px;
    color: black;
}

.reservation-customer-info input {
    border-radius: 4px;
    padding: 15px 30px;
    height: 15px;
}

.reservation-customer-info label {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: black;
    padding-left: 10px;
}

.reservation-info-bubble {
    display: inline-block;
    margin-left: 8px;
    cursor: pointer;
    font-size: 12px;
    background-color: #ddd;
    color: #000 !important;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 16px;
}

.reservation-info-bubble:hover {
    background-color: #bbb;
}


.reservation-customer-info label span, .reservation-checkCondition span {
    color: red;
}

.reservation-dates span {
    color: red;
    margin-top: 10px;
}

.reservation-gite-selector {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
    width: 80%;
}

.reservation-gite-selector select {
    padding: 15px;
    margin-right: 10px;
    flex: 1;
}

.reservation-price-per-night {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: inline-block;
    text-align: right;
    flex: 1;
}

.reservation-price-per-night span {
    font-size: 20px;
}

.reservation-dates {
    display: flex;
    width: 80%;
}

.reservation-dates-calendar {
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    margin-top: 5px;
    flex: 1;
    width: 150px;
    background-color: white;
    border: 1px solid black;
}  

.reservation-dates-calendar.disabled {
    background-color: #f0f0f0;
    color: #a0a0a0; 
    border-color: #dcdcdc; 
    cursor: not-allowed; 
  }
  

.reservation-total-price {
    margin-bottom: 20px;
    font-weight: bold;
    width: 80%;
}

.reservation-checkCondition {
    align-self: flex-start;
    margin-left: 10%;
    margin-bottom: 30px;
}

.reservation-pay {
    padding: 15px 20px;
    background-color: #ACB9A5;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10%;
    align-self: flex-end;
    width: 250px;
    font-size: 25px;
}

.reservation-total-price span {
    margin-left: 5px;
}

.reservation-pay:hover {    
    background-color: #8c9785;
}

.reservation-pay:disabled {
    background-color: #ACB9A580;
}
  
/* Mobile ---------------------------- */
@media (max-width: 699px) { 
    .reservation {
        margin-top: 0;
    }
    
    .reservation-container {
        display: flex;
        width: 100%;
        margin: auto;
        margin-top: 15%;
        margin-bottom: 10%;
    }
       
    .reservation-details {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 10px;
        align-items: center;
    }

    .reservation-customer {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    
    .reservation-customer-info {
        display: flex;
        flex-direction: column;
        align-items: left;
        padding: 0;
        color: black;
        width: 80%;
    }
    
    .reservation-customer-info input {
        border-radius: 4px;
        padding: 15px 30px;
        height: 15px;
    }
    
    .reservation-customer-info label {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: black;
        padding-left: 10px;
    }
    
    .reservation-gite-selector {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        width: 100%;
    }
    
    .reservation-gite-selector select {
        padding: 10px;
        margin-right: 10px;
        flex: 1;
    }
    
    .reservation-price-per-night {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
        display: inline-block;
        text-align: right;
        flex: 1;
    }

    .reservation-details {
        display: flex;
        width: 100%;
        align-items: flex-start;
    }

    .reservation-email {
        padding-left: 0;
    }
    
    .reservation-dates {
        display: flex;
        width: 100%;
        justify-content: center;
    }
    
    .reservation-dates input[type="date"] {
        padding: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
        flex: 1;
    }
    
    .reservation-total-price {
        margin-bottom: 20px;
        font-weight: bold;
        width: 100%;
    }
    
    .reservation-pay {
        padding: 15px 20px;
        background-color: #ACB9A5;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-right: 10%;
        align-self: flex-end;
        width: 250px;
        font-size: 25px;
    }
    
    .reservation-total-price span {
        margin-left: 0px;
    }

    .reservation-checkCondition {
        align-self: flex-start;
        margin-left: 0%;
        margin-bottom: 30px;
    }
    
    .reservation-pay:hover {    
        background-color: #8c9785;
    }
    
    .reservation-pay:disabled {
        background-color: #ACB9A580;
    }   
}


/* CheckoutForm *****************************************/
.checkout-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.checkout-form {
    width: 30%;
    display: flex;
    flex-direction: column;
}

.checkout-element-container {
    border: 1px solid rgb(173, 173, 173);
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
}

.checkout-elements-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.checkout-date-container {
    border: 1px solid rgb(173, 173, 173);
    border-radius: 4px;
    padding: 10px;
    margin-right: 5px;
    width: 70%;
}

.checkout-cvc-container {
    border: 1px solid rgb(173, 173, 173);
    border-radius: 4px;
    padding: 10px;
    margin-left: 5px;
    width: 20%;
}

.checkout-bouton-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.checkout-pay {
    margin-top: 30px;
    margin-bottom: 100px;
    padding: 15px 20px;
    background-color: #ACB9A5;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    align-self: center;
    width: 250px;
    font-size: 25px;
}

.checkout-pay:hover {    
    background-color: #8c9785;
}

/* Mobile ---------------------------- */
@media (max-width: 699px) { 
    .checkout-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 100px;
    }
    
    .checkout-form {
        width: 90%;
    }
    
    .checkout-bouton-container {
        display: flex;
        width: 100%;
        justify-content: center;
    }
    
    .checkout-pay {
        margin-top: 30px;
        margin-bottom: 100px;
        padding: 10px 15px;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        align-self: center;
        width: 200px;
        font-size: 20px;
    }
}