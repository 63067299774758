@font-face {
    font-family: "OpenSans";
    src: url("../../font/OpenSans-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "OpenSansBold";
    src: url("../../font/OpenSans-Bold.ttf") format("truetype");
    font-display: swap;
}

    
.terms {
    margin: 10% 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.terms-article, .terms-title, .terms-intro {
    width: 60%;
}

.terms h1, .terms h2 {
    font-family: "OpensSansBold";
}

.terms p {
    font-family: "OpensSans";
}

.terms-add-article-button, .terms-add-line-button, .terms-edit-button {
    display: inline-block;
    padding: 3px 40px;
    margin-top: 20px;
    background-color: #ACB9A5;
    color: white;
    text-decoration: none;
    border-radius: 0;
    font-size: 20px;
    border: none;
    width: 200px;
}