@font-face {
    font-family: "Arimo";
    src: url("../../font/Arimo-Regular.ttf") format("truetype");
    font-display: swap;
}

.admin {
    margin: 0 auto;
    display: flex;
}

.admin-connexion {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.admin-connexion h1 {
    font-family: "Arimo";
    font-size: 26px;
    margin: 0;
}

.admin-connexion p {
    font-family: "Arimo";
    font-size: 18px;
    margin: 0;
    margin-bottom: 20px;
}

.admin-form {
    display: flex;
    flex-direction: column;
    padding: 2% 0;
}

.admin-form-control {
    position: relative;
    margin-bottom: 15px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    font-family: "Neris";
    font-size: 14px;
    color: #1B1446;
}

.admin-form-control input {
    border: none;
    background-color: white;
    border-radius: 30px;
    border: 1px solid #EEEEEE;
    padding: 10px;
    width: 100%;
    height: 30px;
    padding-left: 40px;
    margin-left: 30px;
}

.admin-form-control input[type="password"] {
    -webkit-text-security: disc;
}

.admin-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    color: yellow;
}

.admin-form-control label {
    margin-bottom: 10px;
    align-self: flex-start;
    text-align: left;
}

.admin-button {
    display: inline-block;
    padding: 20px 80px;
    margin-top: 20px;
    background-color: #ACB9A5;
    color: white;
    text-decoration: none;
    border-radius: 0;
    font-size: 18px;
    border: none;
    width: 100%;
}

.admin-button:hover {
    background-color: #8c9785;
}

/* Mobile ---------------------------- */
@media (max-width: 699px) { 
    .admin {
        flex-direction: column;
    }

    .admin img {
        height: 50vh;
        width: 100%;
    }
    
    .admin-connexion {
        width: 100%;
        margin-top: 30px;
    }
    
    .admin-connexion h1 {
        font-family: "Arimo";
        font-size: 24px;
        margin: 0;
    }
    
    .admin-connexion p {
        font-family: "Arimo";
        font-size: 18px;
        margin: 0;
        margin-bottom: 20px;
    }

    .admin-form-control input {
        margin: 0 15px;
    }    
    
    .admin-icon {
        width: 24px !important;
        height: 24px !important;
    }
    
    .admin-form-control label {
        margin-bottom: 10px;
        align-self: flex-start;
        text-align: left;
    }
    
    .admin-button {
        padding: 15px 60px;
        margin-top: 20px;
        font-size: 16px;
    }
}