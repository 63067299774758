@font-face {
    font-family: "OpenSans";
    src: url("../../font/OpenSans-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "OpenSansBold";
    src: url("../../font/OpenSans-Bold.ttf") format("truetype");
    font-display: swap;
}

.prices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
.prices-section-choice {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 200px 0;
}

.prices-bg-top-right, .prices-bg-bottom-left {
    position: absolute;
    width: auto;
    height: auto;
}

.prices-bg-top-right {
    top: -50px;
    right: 0;
}

.prices-bg-bottom-left {
    bottom: -50px;
    left: 0;
}

.prices-content {
    background-color: #F7F8F2;
    width: 90%;
    height: 80%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.prices-row {
    display: flex;
    justify-content: space-between;
    margin: 0 50px;
}

.prices-row:first-child {
    border-bottom: 3px solid #EDEEE9;
}

.prices-box {
    margin: 30px 0;
    padding-right: 50px;
}

.prices-middle-box, .prices-end-box {
    margin: 30px 0;
}

.prices-middle-box {
    border-left: 3px solid #EDEEE9;
    border-right: 3px solid #EDEEE9;
}

.prices-box h2, .prices-middle-box h2, .prices-end-box h2 {
    font-size: 36px;
    font-family: "OpenSansBold";
    font-weight: 800;
}

.prices-divider {
    height: 1px;
    background-color: black;
    width: 100%;
}

.prices-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.prices-option-button {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.prices-option-button button {
    margin: 0 20px;
    background-color: #ACB9A51A;
    color: black;    
    border: none;
    font-size: 14px;
    padding: 20px 10px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s ease;
    border-radius: 4px;
}

.prices-option-button button.selected {
    opacity: 1; 
    background-color: #ACB9A5;
    color: white;
}

.prices-option-button button.unselected:not(:disabled):hover {
    opacity: 0.7;
    background-color: #ACB9A5;
    color: white;
}

.prices-option-button button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}


.prices-box2 {
    flex-basis: 33.333%;
    position: relative; 
    padding: 20px;
    background-color: #F6F8FA;
    margin: 25px 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.prices-box2:hover {
    background-color: white;
    display: flex;
    flex-direction: column;
}

.prices-bande {
    position: absolute;
    top: -16px;
    right: 10px;
    z-index: 1;
}

.prices-bande-text {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    color: white;
    padding: 5px; 
    font-size: 34px;
}

.prices-bande-text-icon {
    position: absolute;
    top: 20px;
    right: 0px;
    z-index: 2;
    font-size: 25px;
}

.prices-box2 h3 {
    font-size: 24px;
}

.prices-book-link {
    border: none;
    padding: 20px 120px;
    cursor: pointer;
    margin-top: 50px;
    margin-bottom: 30px;
    align-self: center;
    font-size: 18px;
    border-radius: 10px;
    text-decoration: none;
    color: inherit;
}

.prices-box2:hover .prices-book-link {
    margin-top: 100px;
    background-color: #ACB9A5;
    color: white;
}

.prices-box2 .prices-book-link {
    background-color: #F6F8FA;
    border: 1px solid #00000050;
}

@media (max-width: 1640px) and (min-width: 1450px) { 
    .prices-content {
        width: 95%;
    }

    .prices-box h2, .prices-middle-box h2, .prices-end-box h2 {
        font-size: 32px;
    }

    .prices-row {
        display: flex;
        justify-content: space-between;
        margin: 0 20px;
    }

    .prices-box {
        margin: 30px 0;
        padding-right: 10px;
    }
}

@media (max-width: 1449px) and (min-width: 700px) { 
    .prices-content {
        width: 95%;
    }

    .prices-box h2, .prices-middle-box h2, .prices-end-box h2 {
        font-size: 26px;
    }

    .prices-row {
        display: flex;
        justify-content: space-between;
        margin: 0 10px;
    }

    .prices-option-button button {
        margin: 0 10px;    
        border: none;
        font-size: 12px;
        padding: 15px 10px;
    }

    .prices-box {
        margin: 30px 0;
        padding-right: 10px;
    }

    .prices-box2 h3 {
        font-size: 20px;
    }

    .prices-box2 p {
        font-size: 14px;
    }

    .prices-book-link {
        border: none;
        padding: 20px 100px;
        cursor: pointer;
        margin-top: 50px;
        margin-bottom: 20px;
        font-size: 14px;
    }
}

/* Mobile ---------------------------- */
@media (max-width: 699px) { 
    .prices {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      
    .prices-section-choice {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: 80px 0;
    }
    
    .prices-bg-top-right, .prices-bg-bottom-left {
        position: absolute;
        width: 50%;
        height: auto;
    }
    
    .prices-bg-top-right {
        top: -60px;
        right: 0;
    }
    
    .prices-bg-bottom-left {
        bottom: -60px;
        left: 0;
    }
    
    .prices-content {
        width: 100%;
        height: 80%;
    }
    
    .prices-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
    }
    
    .prices-row:first-child {
        border-bottom: 3px solid #EDEEE9;
    }
    
    .prices-middle-box, .prices-box, .prices-end-box {
        margin: 0;
        padding: 20px 0px;
    }

    .prices-box {
        margin-top: 20px;
    }
    
    .prices-middle-box {
        border-top: 3px solid #EDEEE9;
        border-bottom: 3px solid #EDEEE9;
        border-right: none;
        border-left: none;
    }
    
    .prices-box h2, .prices-middle-box h2, .prices-end-box h2 {
        font-size: 30px;
        margin-top: 0;
    }
    
    .prices-divider {
        height: 1px;
        background-color: black;
        width: 100%;
    }

    .prices-end-box {
        width: 100%;
    }
    
    .prices-option {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    
    .prices-option-button {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    
    .prices-option-button button {
        margin: 0 5px;
    }
    
    .prices-box2, .prices-end-box {
        flex-basis: 100%;
        position: relative; 
        padding: 0;
        margin: 30px 5px;
        border-radius: 10px;
    }
    
    .prices-bande {
        top: 0;
        width: 25%;
        right: 0px;
        z-index: 1;
    }
    
    .prices-bande-text {
        top: 15px;
        right: 5px;
        z-index: 2;
        padding: 5px; 
        font-size: 24px;
    }
    
    .prices-bande-text-icon {
        position: absolute;
        top: 20px;
        right: 0px;
        z-index: 2;
        font-size: 25px;
    }
    
    .prices-box2 h3 {
        font-size: 20px;
    }
    
    .prices-book-link {
        border: none;
        padding: 20px 120px;
        margin-top: 10px;
        margin-bottom: 30px;        
        color: white;
    }
    
    .prices-box2:hover .prices-book-link {
        margin-top: 10px;
        color: white;
        border: none;
    }
    
    .prices-box2 .prices-book-link {
        border: 1px solid #00000050;
    }
}
  
