@font-face {
    font-family: "OpenSans";
    src: url("../../font/OpenSans-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "OpenSansBold";
    src: url("../../font/OpenSans-Bold.ttf") format("truetype");
    font-display: swap;
}

.about-section1 {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    z-index: -1;
}

.about-video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    
}

.about-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about-content {
    background-color: white;
}

.about-section1 img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.about-section-slide {
    height: 10vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: #ACB9A5;
    color: white;
}

.about-slide {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    white-space: nowrap;
    align-items: center;
    animation: slide 15s linear infinite;
    font-size: 25px;
}

.about-slide span {
    padding-left: 40% ;
}

@keyframes slide {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-190%);
    }
}

.about-section2 {
    display: flex;
}

.about-section2-text {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50%;
    justify-content: center;
    margin-top: 5%;
}

.about-section2-image {
    max-width: 60%;
}

.about-section2-text h2 {
    font-size: 45px;
    padding-left: 15%;
    font-family: "OpenSansBold";
}

.about-section2-text p {
    font-size: 16px;
    width: 50%;
    padding-left: 15%;
    font-family: "OpenSans";
}

.about-edit-button {
    display: inline-block;
    padding: 3px 20px;
    margin-left: 30px;
    background-color: #ACB9A5;
    color: white;
    text-decoration: none;
    border-radius: 0;
    font-size: 20px;
    border: none;
    max-width: 50%;
    font-family: "OpenSansBold";
}

.about-section2-text-edit-area {
    width: 80%;
    font-size: 16px;
    height: 200px;
    margin-left: 30px;
}

.about-section2-button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #ACB9A5;
    color: white;
    text-decoration: none;
    border-radius: 0;
    font-size: 24px;
    margin-left: 15%;
    width: 200px;
    text-align: center;
}

.about-section2-button:hover, .about-section3-button:hover {
    background-color: #8c9785;
}

.about-section3 {
    display: flex;
    width: 100%;
}

.about-section3-button-container {
    flex: 1;
    width: 30%;
    background-color: #ACB9A5;
    position: relative;
    display: flex;
    justify-content: center;
}

.about-section3-button {
    position: absolute;
    bottom: 20%;
    padding: 10px 20px;
    margin-top: 20px;
    color: white;
    text-decoration: none;
    border-radius: 0;
    font-size: 24px;
    text-align: center;
    border: 2px solid white;
}

.about-section3-text-container {    
    flex-grow: 0;
    flex-shrink: 0; 
    flex-basis: auto; 
    width: 70%;
}

.about-section3-image {
    flex-grow: 0;
    flex-shrink: 0; 
    flex-basis: auto; 
    max-width: 100%;
}

.about-section3-text-container p {
  margin: 50px 30px;  
  width: 30%;
  font-family: "OpenSans";
}

.about-section4 {
    display: flex;
}

.about-section4-text-container {
    background-color: #ACB9A5;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.about-section4-text-edit-area {
    width: 50%;
    font-size: 16px;
    height: 200px;
    margin-left: 50px;
}

.about-section4-image {
    max-width: 60%;
}

.about-section4-edit-button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    background-color: white;
    color: #ACB9A5;
    text-decoration: none;
    border-radius: 0;
    font-size: 24px;
    margin-left: 50px;
    width: 200px;
    text-align: center;
    border: none;
}

.about-section4-text-container h3 {
    font-size: 40px;
    max-width: 50%;
    padding-left: 20%;
    font-family: "OpenSansBold";
}

.about-section4-text-container p {
    font-size: 16px;
    max-width: 50%;
    padding-left: 20%;
    font-family: "OpenSans";
}

.about-section-map {
    display: flex;
    justify-content: center;
    padding: 100px 0;
}

@media (max-width: 1640px) and (min-width: 1450px) { 
    .about-section3-text-container p { 
        width: 50%;
    }    

    .about-section4-image {
        max-width: 50%;
    }
}

@media (max-width: 1449px) and (min-width: 700px) { 
    .about-section2-image {
        max-width: 60%;
    }

    .about-section2-text {
        margin: 30px 0;
    }

    .about-section2-text h2 {
        font-size: 36px;
    }

    .about-section2-text p {
        font-size: 16px;
        width: 70%;
    }
    
    .about-section2-button {
        padding: 10px 10px;
        font-size: 20px;
    }

    .about-section3-text-container p { 
        width: 50%;
    }   

    .about-section-map img {
        width: 90%;
    }

    .about-section4-image {
        max-width: 50%;
    }
}

/* Mobile ---------------------------- */
@media (max-width: 699px) {  
    .about-section1 {
        height: 50vh;
    }
    
    .about-section1 img {
        height: 100%;
    }
    
    .about-section2 {
        flex-direction: column;
    }
    
    .about-section2-text {
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-section2-text h2, .about-section2-text p {
        padding: 0;
        width: 80%;
        align-self: center;
      text-align: justify;
    }

    .about-section2-button {
        margin: 30px 0;
    }
    
    .about-section3 {
        flex-direction: column;
    }
    
    .about-section3-button-container {
        min-height: 300px;
        min-width: 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .about-section3-button {
        position: relative;
        padding: 10px 20px;
        margin-top: 0;
    }
    
    .about-section3-image {
        flex-shrink: 0; 
        max-width: 100%;
    }

    .about-section3-text-container { 
        width: 100%;
      }
    
    .about-section3-text-container p {
      margin: 20px;  
      width: 90%;
      align-self: center;
      text-align: justify;
    }
    
    .about-section4 {
        flex-direction: column;
    }
    
    .about-section4-text-container {
        background-color: #ACB9A5;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 30px 0;
    }
    
    .about-section4-text-container h3 {
        font-size: 36px;
        max-width: 100%;
        margin: 10px 0;
        padding-left: 0;
    }
    
    .about-section4-text-container p {
        font-size: 16px;
        max-width: 75%;
        margin: 20px 0;
        padding-left: 0;
    }

    .about-section-map img {
        width: 100%;
        height: 100%;
    }
}