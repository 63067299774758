@font-face {
    font-family: "LoraBold";
    src: url("../../font/Lora-Bold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "OpenSans";
    src: url("../../font/OpenSans-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "OpenSansBold";
    src: url("../../font/OpenSans-Bold.ttf") format("truetype");
    font-display: swap;
}

.home-section1 {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    z-index: -1;
}

.home-video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    
}

.home-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-content {
    background-color: white;
}

.home-section2 {
    text-align: center;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-self: center;
}

.home-section2 h1 {
    font-size: 64px;
    color: #ACB9A5;
    font-family: "LoraBold";
}

.home-slider-container {
    width: 80%;
    margin: 0 auto;
    max-height: 100vh;
    background-color: white;
  }
  

.home-thumbnail-container {
    width: 100%;
    max-height: 100vh;
    background-color: white;
}

.home-thumbnail {
    width: 100%;
    height: auto;
    max-height: 100vh;
    background-color: white;
}

.home-slider-edit {
    margin: 30px 0;
}

.home-add-image {
    width: 30px;
    margin-left: 50px;
}

.home-section-services-text-edit {
    width: 100%;
    font-size: 20px;
}

.home-section-services-text-edit-area {
    width: 50%;
    font-size: 16px;
    height: 200px;
}

.home-edit-button {
    display: inline-block;
    padding: 3px 40px;
    margin-top: 20px;
    background-color: #ACB9A5;
    color: white;
    text-decoration: none;
    border-radius: 0;
    font-size: 20px;
    border: none;
}

.home-service-item-edit {
    width: 100px;
}

.home-edit-button:hover, .home-services-button:hover {
    background-color: #8c9785;
}

.home-section-services {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.home-services-input {
    width: 50%;
}

.home-services-content {
    display: flex;
    justify-content: space-between;
    width: 75%;
}

.home-services-text {
    flex-basis: 35%;
}

.home-services-text h2 {
    font-size: 40px;
    font-family: "OpenSansBold";
    margin: 0;
}

.home-services-text p {
    font-size: 16px;
    font-family: "OpenSans";
}

.home-services-button {
    background-color: #ACB9A5; 
    color: white;
    padding: 16px 32px;
    text-decoration: none;
    border-radius: 35px;
    display: inline-block;
    font-family: "OpenSans";
    font-size: 16px;
    margin-top: 20px;
}

.home-services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-basis: 65%;
}

.home-service-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 22%;
    padding: 30px 0;
    margin-bottom: 20px;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    font-size: 16px;
    font-family: "OpenSans";
}

.home-service-item p {
    text-align: center;
}

.home-service-icon {
    margin-bottom: 10px;
}

.home-section-map {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
}

@media (max-width: 1449px) and (min-width: 700px) { 
    .home-service-item {
        padding: 0;
    }

    .home-section-map img {
        width: 90%;
    }
}

/* Mobile ---------------------------- */
@media (max-width: 699px) {  
    .home-section1 {
        height: 50vh; 
    }
    
    .home-section2 h1 {
        margin: 0;
        font-size: 50px;
    }
    
    .home-slider-container {
        width: 100%;
        margin-top: 20px;
      }
      
    
    .home-thumbnail-container {
        width: 100%;
        max-height: 50vh;
        background-color: white;
    }
    
    .home-thumbnail {
        width: 100%;
        height: auto;
        max-height: 30vh;
        background-color: white;
    }
    
    .home-slider-edit {
        margin: 30px 0;
    }
    
    .home-add-image {
        width: 30px;
        margin-left: 50px;
    }
    
    .home-section-services {
        display: flex;
        align-items: center;
        justify-content: center;
        padding:0;
        margin: 0;
    }
    
    .home-services-content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    .home-services-text {
        flex-basis: 100%;
        padding: 10px 30px;
    }
    
    .home-services-text h2 {
        font-size: 30px;
    }
    
    .home-services-text p {
        font-size: 16px;
    }
    
    .home-services-button {
        color: white;
        padding: 16px 32px;
        text-decoration: none;
        border-radius: 35px;
        display: inline-block;
        margin: 30px 0px;
    }
    
    .home-services-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        flex-basis: 100%;
    }
    
    .home-service-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40%;
        padding: 30px 0;
        margin-bottom: 20px;
        border: 1px solid #E8E8E8;
        border-radius: 8px;
    }
    
    .home-service-icon {
        margin-bottom: 10px;
    }
    
    .home-section-map img {
        width: 100%;
        height: 100%;
    }
}

  